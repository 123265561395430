<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">عرض بيانات المورد</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-6">
                <table class="table table-bordered">
                    <tr >
                      <th>الاسم</th>
                      <td>{{ supplier.name }}</td>
                    </tr>
                    <tr>
                      <th>رقم الهاتف</th>
                      <td>{{ supplier.mobile }}</td>
                    </tr>
                    <tr>
                      <th>البريد الالكتروني</th>
                      <td>{{ supplier.email }}</td>
                    </tr>
                    <tr>
                      <th>الوصف</th>
                      <td>{{ supplier.description }}</td>
                    </tr>
                </table>
              </div>
            </div>
            <CButton color="warning" @click="goBack">رجوع</CButton>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Multiselect from "vue-multiselect";
import swal from "sweetalert";
import $ from "jquery";
export default {
  name: "EditSubCategory",
  data() {
    return {
      supplier: [],
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.$http
      .get(`${process.env.VUE_APP_URL}suppliers/${this.$route.params.id}`)
      .then((response) => {
        this.supplier = response.data.data;
      });
  },
  methods: {
    goBack() {
      this.categoriesOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/suppliers" });
    },
  },
};
</script>
<style scoped>
th {
    background-color: #3c4b64;
    color: #fff;
}
</style>
